import API from '../API/api'
import { checkSejourFromNav } from './sejour'

const initialState = {
  menu : [],
  backGroundColor : {
    backgroundColor : "white",
    minHeight : "54px"
  },
  backGroundColorFixed : {
    backgroundColor : "#015b95",
    color : "white"
  }
}

async function fetchNavbar() {
  let newData = new API();
  return await newData.getMenu()
}

function makeNavbar(menu) {
  return {
    type : 'SET_NAVBAR',
    menu
  }
}

function errorNavbar(error) {
  return {
    type : 'ERROR_NAVBAR',
    error
  }
}

export function generateNavbar() {
  return function (dispatch) {
    return fetchNavbar().then(
      response => ([
        dispatch(makeNavbar(response)),
        dispatch(checkSejourFromNav(response))
      ]),
      error => dispatch(errorNavbar(error))
    )
  }
}


const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NAVBAR" :
      state = {
        ...state,
        menu : action.menu
      }
    break;


    case "ERROR_NAVBAR" :
      state = initialState
    break;


    default :
  }
  return state
}

export default menuReducer
