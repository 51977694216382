import React from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux'
import { isMobile } from "react-device-detect";
import './cookies.css'
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdbreact';

const mapStateToProps = (state) => ({language: state.language, myCookies: state.myCookies})

const mapDispatchToProps = (dispatch) => {
    return {
      setCookies: (payload) => {
        dispatch({
          type : "SET_COOKIES",
          payload : payload
        })
      }
    }
  }

export class MyCookies extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    }

    constructor(props) {
      super(props)

      this.state = {
        show : false
      }
    }

    toggle = () => {
      this.setState({show : false})
      const { cookies } = this.props;

      var timeExpire = new Date();
      timeExpire.setDate(timeExpire.getDate()+7);

      cookies.set('isCookies', false, {
          path: '/',
          expires: timeExpire
      })
      this.props.setCookies("false")
    }

    componentDidMount() {
        if (this.props.isCookies) {
            this.props.setCookies(this.props.isCookies)
            this.props.myCookies.myCookies = this.props.isCookies
        }
        else {
            const {cookies} = this.props
            if (cookies.get('isCookies') && cookies.get('isCookies') === "false") {
                this.props.setCookies("false")
            }
            else {
              this.props.setCookies("true")
              this.setState({show : true})
            }
        }
    }

    renderContent() {
        if (this.props.myCookies.myCookies === "true") {
          if (isMobile || this.props.language.mobile) {
            return (
              <MDBContainer className="positionCookies">
                <MDBRow>
                  <MDBCol size="12" lg="10">
                    {this.props.language.langue === "fr" ? " En poursuivant votre navigation, vous acceptez l’utilisation de cookies, afin d’améliorer votre expérience utilisateur." : "Accept cookies"}
                  </MDBCol>
                  <MDBCol size="12" lg="10">
                      <MDBBtn size="sm" color="info" onClick={this.toggle}>OK</MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            )
          }
          else {
            return (
              <div className="positionCookiesDesktop">
              <MDBContainer>
                <MDBRow className="justify-content-center responsive-cookie pb-2">
                  <MDBCol size="12" lg="10">
                    <p>{this.props.language.langue === "fr" ? " En poursuivant votre navigation, vous acceptez l’utilisation de cookies, afin d’améliorer votre expérience utilisateur." : "Accept cookies"}
                    &nbsp;<a style={{color : "white"}} href="/cookies">En savoir plus</a>
                    </p>
                  </MDBCol>
                  <MDBCol size="12" lg="2">
                    <MDBBtn size="sm" color="info" className="marginButtonAccept w-100" onClick={this.toggle}>Accepter</MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              </div>
            )
          }
        }
        else {
            return
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderContent()}
            </React.Fragment>
        )
    }
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(MyCookies))
