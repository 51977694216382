import React, { useState,useRef,useEffect } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import "./NavMenu.css"

const NavMenu = ({menuLinks,local}) => {

  return (
    <Navbar className="navTopBar p-0 mobileNotDisplay" expand="lg">
      <Navbar.Collapse className="container">
        <Nav variant="pills" >
          
          {menuLinks.map((link, i) => {
            return <SectionLink key={i} link={link} local={local} />
          })}

          <Nav.Item className='item-nav'>
            <Nav.Link 
              href="/promotions" 
              className='text-nowrap'>
              <span>
                {local === "fr" ? "Promotions" : "Promotions"}
              </span>
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className='item-nav'>
            <Nav.Link 
              href="/page/devis-groupes" 
              className='text-nowrap'>
              <span>
                {local === "fr" ? "Devis groupes" : "Groups quotes"}
              </span>
            </Nav.Link>
          </Nav.Item>

        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const SectionLink = ({link, local}) => {

  const [showDropdown, setShowDropdown] = useState(false);
  const navLink = useRef();

  useEffect(() => {
    if (navLink.current && navLink.current.firstChild) {
      navLink.current.firstChild.href = `/${link.slug}`;
    }
  }, [link.slug]);

  const handleClick = () => {
    if (navLink.current && navLink.current.firstChild) {
      window.location.href = navLink.current.firstChild.href
    }
  };

  if (link.submenu) {

    return(
      <NavDropdown
        onClick={handleClick}
        ref={navLink}
        className='item-nav'
        title={link.translations[local].name}
        show={showDropdown}
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
      >
        <>
          {link.submenu.map((subLink,i) => 
            <NavDropdown.Item 
              key={i} 
              href={"/" + link.slug + "/" + subLink.slug}>
                {subLink.translations[local].name}
            </NavDropdown.Item>)}
        </>
      </NavDropdown>
    )
  }

  return (<Nav.Link href={"/" + link.slug}>
    <span>
      {link.translations[local].name}
    </span>
    </Nav.Link>) 

}

export {NavMenu};
