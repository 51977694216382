import Autosuggest from 'react-autosuggest'
import React from 'react'
import API from '../API/api'
import axios from 'axios'
import history from '../App/routes/history'
import './header.css'
  
const renderSuggestion = suggestion => {
    if (typeof suggestion.search !== "undefined") {
        return (
            <div className="mt-2">
                <a style={{color : " #08c", fontWeight : "500"}} href={"/recherche?recherche=" + suggestion.query}> Rechercher "{suggestion.query}"</a>
                <hr className="w-100"/>
            </div>
        )
    }
    let metadata = suggestion._source.miniature.match(/\w+-width-\d+-\d+.jpeg/);
    if (metadata) {
      metadata = metadata[0].split('-');
      if (metadata[0] === "various")
        metadata[0] = "product";
    suggestion._source.miniature = `${process.env.REACT_APP_AWS_CDN}/${metadata[0]}/150x-1/${metadata[3]}.${metadata[4]}`;
    }
    return (
        
        <div>
            <a href={suggestion._source.link}>
                <div className="d-flex">
                <img alt={suggestion._source.title.fr} className="imageSearchAutoComplete" src={suggestion._source.miniature}></img>
                    <div className="ml-2 d-inline">
                        <span><a style={{color : " #08c", fontWeight : "500"}} href={suggestion._source.link}>{suggestion._source.title.fr}</a></span>
                        <p style={{color : " #08c", fontWeight : "500"}}><i className="fas fa-map-marker-alt"></i> {suggestion._source.localisation.ville}</p> 
                    </div>
                </div>
            </a>
        </div>
    )
}

const getSuggestionValue = suggestion => suggestion.name;

class Suggest extends React.Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: []
        };    
    }

    onSubmit = (e) => {
        e.preventDefault()
    }

    renderInputComponent = (inputProps) => {
        const props = {
            ...inputProps,
            className: inputProps.className + ' sk-search-box__text'
        }
        return (
            <div className="sk-search-box">
            <div className="sk-search-box__icon"> </div>
            <form onSubmit={this.onSubmit}>
                <input {...props} />
            </form>
            {this.state.loading && (
                <div
                data-qa="loader"
                className="sk-search-box__loader sk-spinning-loader is-hidden"
                ></div>
            )}
            </div>
        )
    }

    onChange = (event, { newValue }) => {
        if (typeof newValue !== "undefined") {
            this.setState({
                value: newValue
            });
        }
    };

    removeAccents = (str) => {
        return str
            .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .replaceAll("'", " ")
    }

    onSuggestionsFetchRequested = async ({ value }) => {

        value = this.removeAccents(value)

        let query = {
            "query": {
              "function_score": {
                "boost": "5",
                "functions": [
                  {
                    "filter": {
                      "match": {
                        "nous_contacter": true
                      }
                    },
                    "random_score": {},
                    "weight": 0
                  },
                  {
                    "filter": {
                      "match": {
                        "nous_contacter": false
                      }
                    },
                    "weight": 70
                  },
                    {
                        "filter": {
                            "bool": {
                                "must_not": {
                                    "exists": {
                                        "field": "min_price"
                                    }
                                }
                            }
                        },
                        "weight": 0
                    }
                 /* {
                    "field_value_factor": {
                      "field": "weight"
                    }
                  }*/
                ],
                "max_boost": 1000,
                "score_mode": "sum",
                "boost_mode": "multiply",
                "min_score": 0
              }
            },
            "aggs": {
              "undefined": {
                "filter": {
                  "bool": {
                    "must": [
                      {
                        // recherche dans les champs localisation, link et miniature
                        "multi_match": {
                          "query": value,
                          "type": "phrase_prefix",
                          "fields": [
                            "localisation",
                            "link",
                            "miniature"
                          ]
                        }
                      },
                      {
                        // ne pas afficher les résultats qui contiennent le mot "camping"
                        "bool": {
                          "must_not": {
                            "match": {
                              "title.fr": "camping"
                            }
                          }
                        }
                      }
                    ]
                  }
                },
                "aggs": {
                  "tophits": {
                    "top_hits": {
                      "size": 7,
                      "_source": [
                        "title.fr",
                        "localisation",
                        "link",
                        "miniature"
                      ]
                    }
                  }
                }
              }
            },
            "size": 0
          }
          
          
        let api = new API()
        let config = api.getAxiosConfig()
        this.axios = axios.create(config)
        let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search?rest_total_hits_as_int=true", query)
        let suggestions = res.data.aggregations.undefined.tophits.hits.hits
        suggestions.unshift({search : true, query : value})
        this.setState({ suggestions: suggestions, query : value });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };


    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: this.props.placeholder,
            value,
            onChange: this.onChange,
            autoFocus : false
        };

        return (
            <form onSubmit={() => {console.log("submit"); history.push(window.location.path); window.location.assign("/recherche?recherche=" + this.state.query)}}>
                <div>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        renderSuggestion={renderSuggestion}
                        getSuggestionValue={getSuggestionValue}
                        renderInputComponent={this.renderInputComponent}
                        inputProps={inputProps} />
                </div>
            </form>
        );
    }
}

export default Suggest