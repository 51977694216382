import React from "react";
import { connect } from 'react-redux'
import './footer.css'
import data from './footer.json'
import Img from 'react-image'
import paiementSecurise from '../assets/img/rea-footer.png'
import API from '../API/api'
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
const PaiementSecuriseLogo = () => <Img className="w-100" alt="paiement-securise" src={paiementSecurise}/>

const mapStateToProps = (state) => {
  return {
    language : state.language,
    sejour : state.sejour
  }
}

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email : "",
      buttonMessage : "S'inscrire à notre newsletter"
    }
  }

  sendNewlettersEmail = () => {
    console.log(this.state.email)
    let api = new API()

    if (this.state.email !== "" && this.state.email.indexOf('@') !== -1 && this.state.email.indexOf('.') !== -1) {
      api.sendNewsLetterEmail(this.state.email)
      .then(
        response => (
          this.setState({buttonMessage : "Vous êtes désormais inscrit à notre newsletter"})
        ),
        erreur => (
          this.setState({buttonMessage : "Vous êtes déjà inscrit"})
        )
      )
    }
  }

  changeHandler = event => {
    this.setState({email : event.target.value})
  }

  lienReseau = () => {

    const Tel = () => <a href="tel:+33467583636">04 67 58 36 36</a>
    
    return (
      <React.Fragment>
      <hr />
      <MDBRow className="m-auto d-flex justify-content-between">
        <a href="/page/qui-sommes-nous">{this.props.language.langue === "fr" ? "Qui sommes-nous ?" : "About us"}</a>
        <a href="/page/faq">FAQ</a>
        <a href="/page/conditions-generales-de-vente">{this.props.language.langue === "fr" ? "CGV" : "Terms and conditions"}</a>
        <a href="/page/avantages-vtr">{this.props.language.langue === "fr" ? "Avantages VTR" : "Our commitments"}</a>
        <a href="/affiliation">{this.props.language.langue === "fr" ? "Programme d'affiliation" : "Affiliate program"}</a>
        <a href="/page/3-fois-sans-frais">{this.props.language.langue === "fr" ? "Paiement en 3 fois sans frais" : "Pay in 3 x free of charge"}</a>
        <a href="/page/paiement-securise">{this.props.language.langue === "fr" ? "Paiement securisé" : "Secured payment"}</a>
        <a href="/page/devis-indiv">{this.props.language.langue === "fr" ? "Aidez-moi à choisir ma station" : "Help me choose my station"}</a>
        <a href="/page/devis-groupes">{this.props.language.langue === "fr" ? "Demande de devis groupes" : "Request for Group Quotes"}</a>
      </MDBRow>

      <hr/>
      <MDBRow className="justify-content-between align-items-center">
      <MDBCol md="3" lg="3">
      <PaiementSecuriseLogo/>
      </MDBCol>
      <MDBCol md="2" lg="2">
      <a href="https://www.avis-verifies.com/avis-clients/vtr-voyages.fr">
        <img src="//cl.avis-verifies.com/fr/cache/c/b/1/cb1053fe-8f4e-f274-29ed-2eb7e93bee2c/widget3/widget02-19990.png" alt="WidgetAvisVerifies"   width="200"/>
      </a>
      </MDBCol>
      <MDBCol md="2" lg="2">
        <a title="voyages etudiants" href="https://www.voyagesetudiants.com/">{this.props.language.langue === "fr" ? "Voyages étudiants" : "Student Travel"}</a>
      </MDBCol>
      <MDBCol md="2" lg="2">
        <a href="https://www.otentikjuniors.fr/">{this.props.language.langue === "fr" ? "Séjours Juniors" : "Kids holidays"}</a>
      </MDBCol>
      <MDBCol md="2" lg="2">
        <a href="https://www.canoelemoulin.fr/">{this.props.language.langue === "fr" ? "Base canoë sur l'Hérault" : "Canoe Base on Herault river "}</a>
      </MDBCol>
      </MDBRow>

      <hr/>

      <MDBRow className="justify-content-between">
      <span style={{textAlign : "center"}}>{this.props.language.langue === "fr" ? 
        <>VTR Voyages / Montpellier | Tél : <Tel /> | E-mail : contact@vtr-voyages.fr | Licence N° AF-IM034110006 - Agrément DDCS N° 03402ET0162 - Assurance RCP0221540 VTR Voyages - Parc Club du Millénaire, bât 25 - 1025 rue Henri Becquerel - 34000 Montpellier</>:
        <>VTR Voyages / Montpellier | Phone : <Tel /> | E-mail : contact@vtr-voyages.fr | Licence N° AF-IM034110006 - Licence DDCS N° 03402ET0162 - Insurance  RCP0221540 VTR Voyages - Parc Club du Millénaire, bât 25 - 1025 rue Henri Becquerel - 34000 Montpellier</>
      }</span>
      </MDBRow>

      <hr />
      <MDBRow className="d-flex align-items-center">
      <MDBCol size="12" md="6" lg="6">
      <p className="text-center text-md-left grey-text">
      &copy; {new Date().getFullYear()} Copyright:{" "} vtr-voyages
      </p>
      </MDBCol>
      
      <MDBCol size="12" md="6" lg="6" className="ml-lg-0">
      <div className="text-center text-md-right">
      <ul className="list-unstyled list-inline">
      <li className="list-inline-item">
      <a href="https://blog.vtr-voyages.fr" className="btn-floating btn-sm rgba-white-slight mx-1">
      <i className="fab fa-wordpress"></i>
      </a>
      </li>
      <li className="list-inline-item">
      <a href="https://www.facebook.com/VtrVoyages/" className="btn-floating btn-sm rgba-white-slight mx-1">
      <i className="fab fa-facebook-f" />
      </a>
      </li>
      <li className="list-inline-item">
      <a href="https://www.instagram.com/vtrvoyages_fr/" className="btn-floating btn-sm rgba-white-slight mx-1">
      <i className="fab fa-instagram" />
      </a>
      </li>
      <li className="list-inline-item">
      <a href="https://www.linkedin.com/company/vtr-voyages" className="btn-floating btn-sm rgba-white-slight mx-1">
      <i className="fab fa-linkedin-in" />
      </a>
      </li>
      <li className="list-inline-item">
      <a href="https://twitter.com/vtrvoyages" className="btn-floating btn-sm rgba-white-slight mx-1">
      <i className="fab fa-twitter" />
      </a>
      </li>
      <li className="list-inline-item">
      <a href="https://www.pinterest.fr/vtrvoyages/" className="btn-floating btn-sm rgba-white-slight mx-1">
      <i className="fab fa-pinterest" />
      </a>
      </li>
      </ul>
      </div>
      </MDBCol>
      </MDBRow>
      </React.Fragment>
    )
  }

  baseFooter = () => {
    let footer = []
    let row = []
    let i = 0
    let j = 0
    let appropriate_data = data.data
    const regex_promo = /promo/gm
    const regex_zone = /zone/gm
    const regex_promotion = /promotion/gm
    const regex_minute = /minute/gm
    const regex_ski = /ski/gm
    const regex_junior = /junior/gm
    const regex_campings = /camping/gm
    const regex_france = /france/gm
    const regex_montagne_ete = /montagne-ete/gm
    const regex_location_montagne = /location-montagne/gm
    const regex_ans = /-ans/gm
    const regex_colo = /colo/gm
    const regex_stage = /stage/gm
    const regex_pied_des_pistes = /pied-des-pistes/gm
    const regex_hotels_clubs = /hotels-clubs/gm
    const regex_location_particulier = /location-particulier/gm

    if (this.props.sejour.sejour === "colonies-de-vacances") {
      appropriate_data = data.data_junior
    }

    if (this.props.sejour.sejour === "sejours-france") {
      appropriate_data = data.data_france
    }

    if (this.props.sejour.sejour === "sejours-ski" || this.props.sejour.sejour === "stations-ski" || this.props.sejour.sejour === "station-ski") {
      appropriate_data = data.data_ski
    }

    if (this.props.sejour.sejour === "sejours-ski") {
      appropriate_data = data.data_ski_location
    }

    if (this.props.sejour.sejour === "campings") {
      appropriate_data = data.data_campings
    }

    //Check in slug
    if (typeof this.props.sejour.slug !== "undefined") {
      if (this.props.sejour.slug.match(regex_france) !== null) {
        appropriate_data = data.data_france
      }

      if (this.props.sejour.slug.match(regex_ski) !== null || this.props.sejour.slug.match(regex_pied_des_pistes) !== null || this.props.sejour.slug.match(regex_hotels_clubs) !== null || this.props.sejour.slug.match(regex_location_particulier) !== null) {
        appropriate_data = data.data_ski
      }

      if (this.props.sejour.slug.match(regex_junior) !== null || this.props.sejour.slug.match(regex_ans) !== null || this.props.sejour.slug.match(regex_colo) !== null || this.props.sejour.slug.match(regex_stage) !== null) {
        appropriate_data = data.data_junior
      }

      if (this.props.sejour.slug === "location") {
        appropriate_data = data.data_ski_location
      }

      if (this.props.sejour.slug === "montagne-vacances-ete" || this.props.sejour.slug === "location-chalet-aout" || this.props.sejour.slug === "location-chalet-alpes-ete" || this.props.sejour.slug === "location-chalet-montagne-ete-avec-piscine" || this.props.sejour.slug === "location-aout-alpes" || this.props.sejour.slug === "location-alpes-du-sud-ete" || this.props.sejour.slug === "location-ete-alpes" || this.props.sejour.slug === "location-hautes-alpes-ete" || this.props.sejour.slug === "location-savoie-ete" || this.props.sejour.slug.match(regex_montagne_ete) !== null || this.props.sejour.slug.match(regex_location_montagne) !== null) {
        appropriate_data = data.data_montagne_ete
      }

      if (this.props.sejour.sejour === "sejours-ski" && this.props.sejour.slug === "hebergement-forfait") {
        appropriate_data = data.data_ski_forfait
      }

      if (this.props.sejour.slug.match(regex_promo) !== null || this.props.sejour.slug.match(regex_zone) !== null 
          || this.props.sejour.slug.match(regex_promotion) !== null || this.props.sejour.slug.match(regex_minute) !== null) {
        appropriate_data = data.data_promo_ski
      }

      if (this.props.sejour.slug.match(regex_campings) !== null) {
        appropriate_data = data.data_campings
      }
    }

    if (this.props.language.langue !== "fr") {
      appropriate_data = data.data
    }
      
    for (let theme of appropriate_data) {
      let title = this.props.language.langue === "fr" ? theme.title.fr : theme.title.en
      row = []
      j = 0
      for (let sublink of theme.sublinks) {
        row.push(
          <p key={j}>
            <a title={this.props.language.langue === "fr" ? sublink.title.fr : sublink.title.en} href={sublink.link}>{this.props.language.langue === "fr" ? sublink.title.fr : sublink.title.en}</a>
          </p>
        )
        j++
      }
      footer.push(
        <MDBCol className="specialColMobileFooter" key={i} md="2" lg="2" xl="2" xs="12" sm="12">
          {
            title === "SKI" || title === "Ski" ?
            <a title="séjours ski" href="/sejours-ski"><p className="text-uppercase titleFooter mb-4 font-weight-bold">{title}</p></a>
            :
            <p className="text-uppercase titleFooter mb-4 font-weight-bold">{title}</p>
          }
          {row}
        </MDBCol>
      )
      i++
    }

    return (
        <footer className="font-small pt-4 mt-4 footerColor">
        <MDBContainer className="text-center text-md-left">
          <MDBRow className="mt-3 pb-3">
            {footer}
            <hr className="w-100 specilaMobileHr clearfix d-md-none" />
          </MDBRow>
          {this.lienReseau()}
        </MDBContainer>
      </footer>
    )
  }

  render() {
    return this.baseFooter()
  }
}
export default connect(mapStateToProps)(Footer)
