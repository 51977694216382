import API from '../API/api'

const initialState = {
  search : false,
  sejour : "",
  slug  : "",
  value : "",
  canShow : false,
  offre : false,
  pageNotFound : false,
  meilleurVentes : [],
  coup_coeur : [],
  destinations_populaires : [],
  promotions : [],
  seo : [],
  hits_recherche : [],
  carousel : [],
  tripAdvisor : null,
  station : null
}

function saveSejourData(sejour, slug, value) {
  return {
    type : "SET_VALUE",
    sejour,
    slug,
    value
  }
}

function setOffre(payload) {
  return {
    type : "SET_OFFRE",
    payload
  }
}

function setSearch(payload) {
  return {
    type : "SET_SEARCH",
    payload
  }
}

function setCanShow(payload) {
  return {
    type : "SET_SHOW",
    payload
  }
}

function setPageNotFound(payload) {
  return {
    type: "SET_PAGE__NOT_FOUND",
    payload
  }
}

export function checkSejourFromNav(menu) {
  return function (dispatch, getState) {
    if (getState().sejour.slug && !getState().sejour.value) {
      //Page recherche / theme / submenu
      //set find to false to activate 404
      //les pages themes ne sont actuellement pas dans le json du menu
      let find = true
      for (let page of menu) {
        if (page.slug === getState().sejour.sejour) {
          for (let submenu of page.submenu) {
            if (submenu.slug === getState().sejour.slug) {
              find = true
            }
          }
        }
      }
      if (find === true) {
        return (Promise.all([
          dispatch(setSearch(false)),
          dispatch(setCanShow(true))
        ]))
      }
      else {
        return (Promise.all([
          dispatch(setSearch(false)),
          dispatch(setCanShow(false)),
          dispatch(setPageNotFound())
        ]))
      }
    }
    else if (getState().sejour.value) {
      //Page Offre
      return (Promise.all([
        dispatch(setSearch(false)),
        dispatch(setOffre(true)),
        dispatch(setCanShow(true)),
      ]))
    }
    else {
      let find = false
      for (let page of menu) {
        if (page.slug === getState().sejour.sejour) {
          find = true
          return (Promise.all([
            dispatch(setSearch(true)),
            dispatch(setCanShow(true))
          ]))
        }
      }
      if (find === false ) {
        return (Promise.all([
          dispatch(setSearch(false)),
          dispatch(setCanShow(true))
        ]))
      }
    }
  }
}

function loadCoupCoeur(sejour) {
  return dispatch => {
    let newData = new API();
    return newData.getCoupCoeur(sejour)
    .then (
      response => dispatch({ type : "SET_COUP_COEUR", response}),
      erreur => console.log("erreur coup coeur")
    )
  }
}

function destinationsPopulaires(sejour) {
  return dispatch => {
    let newData = new API();
    return newData.getDestinationsPopulaires(sejour)
    .then (
      response => dispatch({ type : "SET_DESTINATIONS_POPULAIRES", response}),
      erreur => console.log("erreur destinations populaires")
    )
  }
}

function getTripAdvisorReviews(slug) {
  return dispatch => {
    let newData = new API();
    return newData.getTripAdvisorReviews(slug)
    .then (
      response => dispatch({ type : "SET_TRIPADVISOR", response}),
      erreur => console.log("erreur destinations populaires")
    )
  }
}

function getStationInfo(slug) {
  return dispatch => {
    let newData = new API();
    return newData.getStationInfo(slug)
    .then (
      response => dispatch({ type : "SET_STATION_DATA", response}),
      erreur => console.log("erreur destinations populaires")
    )
  }
}

function meilleurVentes(sejour) {
  return dispatch => {
    let newData = new API();
    return newData.getMeilleurVentes(sejour)
    .then (
      response => dispatch({ type : "SET_MEILLEUR_VENTES", response}),
      erreur => console.log("erreur meileurs ventes")
    )
  }
}

function promotions(sejour) {
  return dispatch => {
    let newData = new API();
    return newData.getPromotions(sejour)
    .then (
      response => dispatch({ type : "SET_PROMOTIONS", response}),
      erreur => console.log("erreur promotions")
    )
  }
}

function getSeo(sejour, station_ski = false) {
  return dispatch => {
    let newData = new API();
    return newData.getSeo(sejour, station_ski)
    .then (
      response => dispatch({ type : "SET_SEO", response}),
      erreur => console.log("erreur seo")
    )
  }
}

export function getCarousel(sejour) {
  return dispatch => {
    let newData = new API();
    return newData.getCarousel(sejour)
    .then (
      response => dispatch({ type : "SET_CAROUSEL", response}),
      erreur => console.log("erreur seo")
    )
  }
}

export function loadSejourStation() {
  return function (dispatch) {
    return (Promise.all([
      dispatch(saveSejourData("station-ski", undefined, undefined)),
    ]))
  }
}

export function loadSejour(sejour, slug, value) {
  return function (dispatch) {
    //page séjour load séjour
    if (!slug && !value) {

      return (Promise.all([
        dispatch(saveSejourData(sejour, slug, value)),
        dispatch(setSearch(true)),
        dispatch(loadCoupCoeur(sejour)),
        dispatch(destinationsPopulaires(sejour)),
        dispatch(meilleurVentes(sejour)),
        dispatch(promotions(sejour)),
        dispatch(getSeo(sejour)),
        dispatch(getCarousel(sejour))
      ]))
    }

    //page recherche ou theme
    if (slug && !value) {
      return (Promise.all([
        dispatch(saveSejourData(sejour, slug, value)),
        dispatch(setSearch(false)),
        dispatch(getSeo(slug, sejour))
      ]))
    }

    //Page offre
    if (value) {
      return (Promise.all([
        dispatch(saveSejourData(sejour, slug, value)),
        dispatch(getTripAdvisorReviews("/" + sejour + "/" + slug + "/" + value)),
        dispatch(getStationInfo(("/" + sejour + "/" + slug + "/" + value))),
        dispatch(setSearch(false)),
      ]))
    }
  }
}

const sejourReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCH" :
    state = {
      ...state,
      search : action.payload
    }
    break;

    case "SET_STATION_DATA" : 
    state = {
      ...state,
      station : action.response.data
    }
    break;

    case "SET_TRIPADVISOR" : 
    state = {
      ...state,
      tripAdvisor : action.response.data
    }
    break;

    case "SET_SHOW" :
    state = {
      ...state,
      canShow : action.payload
    }
    break;

    case "SET_PAGE__NOT_FOUND" :
    state = {
      ...state,
      pageNotFound : action.payload
    }
    break;

    case "SET_VALUE" :
    state = {
      ...state,
      sejour : action.sejour,
      slug : action.slug,
      value : action.value
    }
    break;

    case "SET_OFFRE" :
    state = {
      ...state,
      offre : action.payload
    }
    break;

    case "SET_COUP_COEUR" :
    state = {
      ...state,
      coup_coeur : action.response
    }
    break;

    case "SET_PROMOTIONS" :
    state = {
      ...state,
      promotions : action.response
    }
    break;

    case "SET_DESTINATIONS_POPULAIRES" :
    state = {
      ...state,
      destinations_populaires : action.response
    }
    break;

    case "SET_HITS_RECHERCHE" :
    state = {
      ...state,
      hits_recherche : action.hits
    }
    break;

    case "SET_MEILLEUR_VENTES" :
    state = {
      ...state,
      meilleurVentes : action.response
    }
    break;

    case "SET_STATIONS_SKI" : 
    state = {
      ...state,
      sejour : "stations-ski"
    }
    break;

    case "SET_SEO" :
    state = {
      ...state,
      seo : action.response
    }
    break;

    case "SET_CAROUSEL" :
    state = {
      ...state,
      carousel : action.response
    }
    break;

    default :
  }
  return state
}


export default sejourReducer
