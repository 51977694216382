import API from '../API/api'

const initialState = {
  sejour : "",
  hebergement : {},
  station : "",
  title : {
    fr : "",
    en : ""
  },
  description : {
    fr : "",
    en : ""
  },
  nb_etoiles : 0,
  carousel_images : [],
  load : false,
  startDate : new Date(),
  endDate : new Date(),
  icon_compris : [],
  avis : [],
  prestations : {},
  link : "",
  min_price : 0,
  typologie : {},
  annulation_sans_frais : null,
  localisation : {
    station : [],
    ville : ""
  },
  marker : {},
  meta : {},
  state : {},
  type_sejour : "",
  sejourSimilaire_hits : [],
  etoiles : 0,
  tags_stay : [],
  startDateDone : false,
  checkFormule : false,
  weight : 0
}

export function getSejourSimilaires(marker, type_sejour, link) {
  return dispatch => {
    let newData = new API()
    return newData.getSejourSimilaires(marker, type_sejour, link)
    .then(
      response => dispatch({ type : "SET_SEJOUR_SIMILAIRES", response}),
      erreur => console.log("erreur sejour similaires" + erreur)
    )
  }
}

const offreReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_STATE" :
    state = {
      ...state,
      state : action.state
    }
    break;

    case "SET_OFFRE" :
    state = {
      ...state,
      offre : action.offre
    }
    break;

    case "SET_START_DATE" :
      state = {
        ...state,
        startDateDone : action.value
      }
    break;

    case "SET_SEJOUR_SIMILAIRES" :
    state = {
      ...state,
      sejourSimilaire_hits : action.response
    }
    break;

    case "SET_FORMULE_DONE":
      state = {
        ...state,
        checkFormule : true
      }
    break;

    case "SET_ALL" :
    state = {
      ...state,
      title : action.title,
      description : action.description,
      nb_etoiles : action.nb_etoiles,
      carousel_images : action.carousel_images,
      hebergement : action.hebergement,
      station : action.station,
      sejour : action.sejour,
      icon_compris : action.icon_compris,
      avis : action.avis,
      typologie : action.typologie,
      prestations : action.prestations,
      link : action.link,
      marker : action.marker,
      min_price : action.min_price,
      nous_contacter : action.nous_contacter,
      meta : action.meta,
      type_sejour : action.type_sejour,
      localisation : action.localisation,
      etoiles : action.etoiles,
      tags_stay : typeof action.tags_stay !== "undefined" ? action.tags_stay : [],
      weight : action.weight,
      annulation_sans_frais : action.annulation_sans_frais,
      load : true
    }
    break;

    default :
  }
  return state
}

export default offreReducer
