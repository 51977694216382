import API from '../API/api'

const initialState = {
  promotion : [],
  pages : [],
  pageFound : true,
}

export function loadPromotionPage(filter = false) {
  return dispatch => {
    let newData = new API()
    return newData.getPromotion(filter)
    .then(
      response => dispatch({ type : "SET_PROMOTION", response}),
      erreur => console.log("erreur promotion" + erreur)
    )
  }
}

export function loadPage(prefix, filter) {
  return dispatch => {
    let newData = new API()
    return newData.getPages(prefix, filter)
    .then(
      response => dispatch({ type : "SET_PAGES", response}),
      erreur => console.log("erreur pages" + erreur)
    )
  }
}

export function loadPages(prefix, filter) {
  return function (dispatch) {
    return (
      Promise.all([
        dispatch(loadPage(prefix, filter)),
      ])
    )
  }
}

export function loadMaterielPage(station) {
  return dispatch => {
    let newData = new API()
    return newData.getSejoursStationDynamic(station)
    .then(
      response => dispatch({ type : "SET_PROMOTION", response}),
      erreur => console.log("erreur promotion" + erreur)
    )
  }
}

const promotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PROMOTION" :
    state = {
      ...state,
      promotion : action.response
    }
    break;

    case "SET_PAGES" :
      if (action.response.pages.length === 0) {
        state = {
          pages : [],
          pageFound : false
        }
      }
      else {
        state = {
          ...state,
          pages : action.response.pages,
          promotion : action.response.promotion
        }
      }
    break;

    default :
  }
  return state
}

export default promotionReducer
