import API from '../API/api'
import { getCarousel } from './sejour'

const initialState = {
  onVousEmmene : [],
  plusPopulaires : [],
  derniereMinutes : {this_week : [], next_week : [], far_week : []},
  bonPlans : [],
  promotions : [],
  coupsCoeur : [],
  seo : []
}

export function fetchOnVousEmmene(path) {
  return (dispatch, getState) => {
    let newData = new API();
    newData.getOnVousEmmene().then(
      response => {
        dispatch({ type : 'SET_ON_VOUS_EMMENE', response })
      },
      erreur => {
        console.log("erreur on vous emmene")
      }
    )
  }
}

export function fetchPlusPopulaires() {
  return dispatch => {
    let newData = new API();
    return newData.getPlusPopulaires()
    .then(
      response => dispatch({ type : 'SET_PLUS_POPULAIRES', response }),
      erreur => console.log("erreur les plus populaires")
    )
  }
}

export function fetchDerniereMinutes() {
  return dispatch => {
    let newData = new API();
    return newData.getDerniereMinutes()
    .then(
      response => dispatch({ type : 'SET_DERNIERE_MINUTES', response }),
      erreur => console.log("erreur derniere minutes")
    )
  }
}

export function fetchBonPlans(path) {
  return dispatch => {
    let newData = new API();
    return newData.getBonPlans(path)
    .then(
      response => dispatch({ type : 'SET_BON_PLANS', response }),
      erreur => console.log("erreur bon plans")
    )
  }
}

export function fetchCoupsCoeur() {
  return dispatch => {
    let newData = new API();
    return newData.getCoupsCoeurs()
    .then(
      response => dispatch({ type : 'SET_COUPS_COEUR', response }),
      erreur => console.log("erreur les plus populaires")
    )
  }
}

function promotions() {
  return dispatch => {
    let newData = new API();
    return newData.getPromotions()
    .then (
      response => dispatch({ type : "SET_PROMOTIONS", response}),
      erreur => console.log("erreur promotions")
    )
  }
}

export function getSeo(sejour) {
  return dispatch => {
    let newData = new API();
    return newData.getSeo(sejour)
    .then (
      response => dispatch({ type : "SET_SEO", response}),
      erreur => console.log("erreur seo")
    )
  }
}

export function loadHomeData() {
  return function (dispatch) {
    return (
      Promise.all([
        //dispatch(fetchOnVousEmmene()),
        dispatch(fetchPlusPopulaires()),
        dispatch(fetchDerniereMinutes()),
        dispatch(fetchCoupsCoeur()),
        dispatch(promotions()),
        dispatch(getCarousel("home"))
        //dispatch(fetchBonPlans('home')),
        //dispatch(getSeo())
      ])
    )
  }
}

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ON_VOUS_EMMENE" :
    state = {
      ...state,
      onVousEmmene : action.response
    }
    break;

    case "SET_PLUS_POPULAIRES" :
    state = {
      ...state,
      plusPopulaires : action.response
    }
    break;

    case "SET_COUPS_COEUR" :
        state = {
          ...state,
          coupsCoeur : action.response
        }
    break;

    case "SET_PROMOTIONS" :
    state = {
      ...state,
      promotions : action.response
    }
    break;

    case "SET_DERNIERE_MINUTES" :
    state = {
      ...state,
      derniereMinutes : action.response
    }
    break;

    case "SET_BON_PLANS" :
    state = {
      ...state,
      bonPlans : action.response
    }
    break;

    case "SET_SEO" :
    state = {
      ...state,
      seo : action.response
    }
    break;

    default:
  }
  return state
}

export default homeReducer
