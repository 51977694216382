import React from 'react';
import { MDBIcon, MDBSideNavCat, MDBSideNavNav, MDBSideNav, MDBContainer, MDBRow, MDBCol, MDBPopover, MDBPopoverBody, MDBBtn } from 'mdbreact';
import './mobile.css'
import { connect } from 'react-redux'
import { generateNavbar } from '../reducers/navbar'
import ReactCountryFlag from "react-country-flag";
import Suggest from '../header/autosuggest';
import { NavMenu } from './NavMenu';

const mapStateToProps = (state) => {
  return {
    menu : state.menu,
    language : state.language
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (langue) => {
      dispatch({
        type : "SET_LANGUAGE",
        payload : langue
      })
    },
    loadNavbar: () => {
      dispatch(generateNavbar())
    }
  }
}

class SideNavPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      showSearch : false
    }
    this.props.loadNavbar()
  }

  changeLangue = (props) => {
    if (this.props.language.langue === "fr") {
      this.props.setLanguage("en")
    }
    else {
      this.props.setLanguage("fr")
    }
  }

  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  generateMenu() {
    let menu = []
    let submenu = []
    let nb_item = 0

    menu.push(<a className="Ripple-parent setBlackColorSideNavSubItem" key={nb_item} href="/"><span className="sv-normal"><i className="fas fa-home" />{this.props.language.langue === "fr" ? "Accueil" : "Home"}</span></a>)
    for (let item of this.props.menu.menu) {
      if (item.submenu.length > 0) {
        submenu = []
        for (let subItem of item.submenu) {
          submenu.push(<a alt={this.props.language.langue === "fr" ? subItem.translations.fr.name : subItem.translations.en.name} title={this.props.language.langue === "fr" ? subItem.translations.fr.name : subItem.translations.en.name} className="Ripple-parent setBlackColorSideNavSubItem" key={nb_item} href={"/" + item.slug + "/" + subItem.slug}><span className="sv-normal">{this.props.language.langue === "fr" ? subItem.translations.fr.name : subItem.translations.en.name}</span></a>)
          nb_item++

          if (item.slug === "sejours-ski" && subItem.slug === "location") {
            nb_item++
            submenu.push(<a alt="stations de ski" title="stations de ski" className="Ripple-parent setBlackColorSideNavSubItem" key={nb_item} href={"/station-ski"}><span className="sv-normal">{this.props.language.langue === "fr" ? "Stations de ski" : "Ski resorts"}</span></a>)
          }
        }
        menu.push(
            <MDBSideNavCat icon={item.icon} iconSize="1x" key={nb_item} className="setBlackColorSideNav" id={item.translations.fr.name} name={this.props.language.langue === "fr" ? item.translations.fr.name : item.translations.en.name}>
            <a title={this.props.language.langue === "fr" ? item.translations.fr.name : item.translations.en.name} alt={this.props.language.langue === "fr" ? item.translations.fr.name : item.translations.en.name} className="Ripple-parent setBlackColorSideNavSubItem" key={nb_item} href={"/" + item.slug}><span className="sv-normal">{this.props.language.langue === "fr" ? ("Nos " + item.translations.fr.name.toLowerCase()) : ("See our " + item.translations.en.name.toLowerCase())}</span></a>
            {submenu}
            </MDBSideNavCat>
        )
      }
      else {
        menu.push(<a className="Ripple-parent setBlackColorSideNavSubItem" key={nb_item} href={"/" + item.slug}><i className={`fa-1x ${item.icon}`}></i><span className="sv-normal">{this.props.language.langue === "fr" ? item.translations.fr.name : item.translations.en.name}</span></a>)
      }
      nb_item++
    }
    menu.push(<a className="Ripple-parent setBlackColorSideNavSubItem" key={nb_item} href="/promotions"><span className="sv-normal">{this.props.language.langue === "fr" ? "Promotions" : "Promotions"}</span></a>)
    menu.push(<a className="Ripple-parent setBlackColorSideNavSubItem" key={nb_item + 1} href="/page/devis-groupes"><span className="sv-normal">{this.props.language.langue === "fr" ? "Devis groupes" : "Groups quotes"}</span></a>)
    return menu
  }


  render() {
    const { isOpen } = this.state;

    return (
      <header>
        <div className="p-1 setSideNavColor">
            <MDBContainer id="sidenav" className="setSideNavColor">
              <MDBRow className="pb-1">
              
              <MDBCol size="8" md="6" lg="3" xl="3" xs="6" className="d-flex pt-0 align-items-center speacialPaddingNavbar">

                <div className="float-left justify-content-start align-items-start desktopDisplay">
                  <MDBIcon className="pointer vtr-blue pt-1" onClick={this.handleToggle} size="2x" icon="bars" />
                </div>

                <a href="/" title="VTR voyages">
                  <div className="ml-3" style={{display : "flex"}}>
                    <img alt="logo vtr voyages" src="https://d1vgytqshqecu2.cloudfront.net/autre/300x-1/logo-vtr-voyages.png" width="100%" className="mobileLogo align-top"/>
                  </div>
                </a>
              </MDBCol>

              <MDBCol className="mobileNotDisplay" md="5" xl="5" lg="5">
                <div className="mt-2 pl-5">
                  <Suggest placeholder={this.props.language.langue === "fr" ? "Rechercher un séjour ou un lieu..." : "Search a place or an holiday..."}/>

                </div>

              </MDBCol>
              
              
              <MDBCol className="alignCenter  d-flex justify-content-end align-items-center pl-0 pr-0" size="4" md="6" lg="4" xl="4" xs="6">
                <div className="float-right desktopDisplay"><MDBIcon onClick={() => this.setState({showSearch : !this.state.showSearch})} className="vtr-blue mt-2 pt-1" icon="search" size="2x" /></div>


                <div className="float-right d-flex align-items-center ml-3">

                  <MDBPopover placement="bottom" popover clickable id="popper-compte">
                      <MDBBtn flat className="m-0 p-0 align-items-center d-flex fontSizePhone">
                        <MDBIcon className="vtr-blue mr-2 mt-2" size="2x" icon="user"/>
                      </MDBBtn>
                      <div>
                        <MDBPopoverBody>
                          <MDBRow>
                            <MDBCol size="12" className="mb-2">
                            <a href="/ma-reservation" style={{color : "black"}}><MDBIcon className="vtr-blue mr-2 mt-2" size="1x" icon="plane"/>Ma réservation</a>
                            </MDBCol>
                            <hr className="w-100 mb-0 mt-0"/>
                            <MDBCol size="12" className="mt-2">
                              <a href="/favoris" style={{color : "black"}}><MDBIcon className="vtr-blue mr-2 mt-2" size="1x" icon="heart"/>Mes favoris</a>
                            </MDBCol>
                          </MDBRow>
                        </MDBPopoverBody>
                      </div>
                  </MDBPopover>

                  <a href="tel:+33467583636" className={"btn-flat btn Ripple-parent m-0 p-0 align-items-center d-flex fontSizePhone"}>
                    <MDBIcon className="vtr-blue mr-2 mt-2" size="2x" icon="phone" />
                  </a>
                  
                </div>
                <div className="float-right d-flex ml-2">

                  <div className="mt-2 mobileNotDisplay text-left pr-3">
                    <p className="m-0 fontSizeHeure">Du lundi au vendredi 9h - 18h</p>
                    <p className="m-0 fontSizePhone">04 67 58 36 36</p>

                  </div>
                </div>
              </MDBCol>
              {
                this.state.showSearch ?
                <MDBCol className="mt-1 mb-1"size="12">
                  <Suggest placeholder={this.props.language.langue === "fr" ? "Rechercher un séjour ou un lieu..." : "Search a place or an holiday..."}/>
                </MDBCol>
                : ""
              }
              </MDBRow>
              <MDBSideNav hidden triggerOpening={isOpen} left breakWidth={1300} data-animate="true" className="setWhiteBackground">
                <MDBSideNavNav>
                {this.generateMenu()}
                <a href={this.props.language.langue === "fr" ? "https://www.vtr-voyages.fr" : "https://www.vtr-voyages.com"}><p className="onRightFlag" onClick={this.changeLangue}><ReactCountryFlag styleProps={{width: '20px', height: '20px', verticalAlign : "sub", color : "white"}} code={this.props.language.langue === "fr" ? "gb" : "fr"} svg/> {this.props.language.langue === "fr" ? "English" : "French"}</p></a>
                </MDBSideNavNav>
                <li>
                  <ul className="social">
                    <li>
                      <a href="https://www.facebook.com/VtrVoyages/">
                        <MDBIcon fab icon="facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/vtrvoyages_fr/">
                        <MDBIcon fab icon="instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/vtr-voyages">
                        <MDBIcon fab icon="linkedin" />
                      </a>
                    </li>
                  </ul>
                </li>
              </MDBSideNav>
            </MDBContainer>
        </div>
        <hr className="mt-0 mb-0"/>
        <NavMenu menuLinks={this.props.menu.menu} local={this.props.language.langue} />
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNavPage)