import React from 'react';
import App from './App/App';
import { hydrate, render } from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from 'react-redux';
import menuReducer from './reducers/navbar'
import languageReducer from './reducers/langage'
import myCookies from './reducers/cookies'
import thunk from 'redux-thunk';
import sejourReducer from './reducers/sejour'
import homeReducer from './reducers/home'
import promotionReducer from './reducers/promotion'
import { BrowserRouter } from "react-router-dom";
import offreReducer from './reducers/offre'
import favorisReducer from "./reducers/favoris";
import history from './App/routes/history'
import { loadableReady } from '@loadable/component'

const rootElement = document.getElementById('root') || document.createElement('div')

const reducer = combineReducers({
                                menu : menuReducer,
                                language : languageReducer,
                                myCookies : myCookies,
                                sejour : sejourReducer,
                                home : homeReducer,
                                offre : offreReducer,
                                promotion : promotionReducer,
                                favorites : favorisReducer
                                })

const store = createStore(reducer, window.__PRELOADED_STATE__, applyMiddleware(thunk))

delete window.__PRELOADED_STATE__

if (typeof document === "undefined") {
    render(<Provider store={store}><BrowserRouter><App {...window.__INITIAL_APP_STATE__}/></BrowserRouter></Provider>, rootElement)
}
else {
    loadableReady(() => {
        hydrate(<Provider store={store}><BrowserRouter history={history} ><App {...window.__INITIAL_APP_STATE__}/></BrowserRouter></Provider>, rootElement)
    });
}
