import React from 'react';
import { Switch } from "react-router-dom";
import loadable from '@loadable/component';
import { loadHomeData } from '../../reducers/home';
import { loadSejour, loadSejourStation } from '../../reducers/sejour';
import { loadPromotionPage, loadPages } from '../../reducers/promotion';
import { renderRoutes } from "react-router-config";

//const ReservationCanoe = loadable(() => import(/* webpackChunkName: "route-reservation-canoe" */ '../../view/canoe'));
const Home = loadable(() => import(/* webpackChunkName: "route-home" */ '../../view/home/home'));
const Sejour = loadable(() => import(/* webpackChunkName: "route-sejour" */ '../../view/sejour/sejour'));
const Recherche = loadable(() => import(/* webpackChunkName: "route-recherche" */ '../../view/recherche/main'));
const TunnelAchat = loadable(() => import(/* webpackChunkName: "route-tunnel-achat" */ '../../view/tunnel-achat/tunnel-achat'));
const CollapsePage = loadable(() => import(/* webpackChunkName: "route-collapse-page" */ '../../view/liens-utiles/questionsReponses/accordeon'));
const QuiSommesNous = loadable(() => import(/* webpackChunkName: "route-qui-sommes-nous" */ '../../view/liens-utiles/quiSommesNous'));
const FormuleSki = loadable(() => import(/* webpackChunkName: "route-formule-ski" */ '../../view/liens-utiles/forfaitSki'));
const PageNotFound = loadable(() => import(/* webpackChunkName: "route-page-not-found" */ '../../view/pageNotFound/pageNotFound'));
const PagePromotion = loadable(() => import(/* webpackChunkName: "route-page-promotion" */ '../../view/promotion/promotion'));
const DevisGroupes = loadable(() => import(/* webpackChunkName: "route-devis-groupes" */ '../../view/devis-groupes/devis-groupes'));
const DevisIndiv = loadable(() => import(/* webpackChunkName: "route-devis-indiv" */ '../../view/devis-indiv/devis-indiv'));
const PostReservation = loadable(() => import(/* webpackChunkName: "route-post-reservation" */ '../../view/formulairePostReservation/post-reservation'));
const StationsSki = loadable(() => import(/* webpackChunkName: "route-stations-ski" */ '../../view/stations-ski/stations-ski'));
const ConfirmPayement = loadable(() => import(/* webpackChunkName: "route-confirm-payement" */ '../../view/confirmPayement/confirmPayement'));
const LocationVetement = loadable(() => import(/* webpackChunkName: "route-location-vetement" */ '../../view/page/location-vetement/location-vetement'));
const VenteFlash = loadable(() => import(/* webpackChunkName: "route-vente-flash" */ '../../view/page/vente-flash/vente-flash'));
const Affiliation = loadable(() => import(/* webpackChunkName: "route-vente-flash" */ '../../view/page/affiliation/affiliation'));
const CookiesPage = loadable(() => import(/* webpackChunkName: "route-cookies-page" */ '../../view/page/cookiesPage/cookies'));
const templateLocationMateriel = loadable(() => import(/* webpackChunkName: "route-template-location-materiel" */ '../../view/page/templateLocationMateriel/templateLocationMateriel'));
const Offre = loadable(() => import(/* webpackChunkName: "route-offre" */ '../../view/offre/offre'));
const Favoris = loadable(() => import(/* webpackChunkName: "route-favoris" */ '../../view/page/favorisPage/favorisPage'));
const Reservation = loadable(() => import(/* webpackChunkName: "route-reservation" */ '../../view/reservation/index'));

export const Routes = [
  {
    path: '/',
    exact : true,
    component: Home,
    loadData: () => loadHomeData()
  },
  {
    path: '/favoris',
    exact : true,
    component: Favoris,
  },
  {
    path: '/ma-reservation',
    exact : true,
    component: Reservation,
  },
  {
    path: '/page/qui-sommes-nous',
    exact : true,
    component: QuiSommesNous,
  },
  // {
  //   path: '/test',
  //   exact : true,
  //   component: ReservationCanoe,
  // },
  // {
  //   path: '/en/test',
  //   exact : true,
  //   component: Reservation,
  // },
  {
    path: '/page/faq',
    exact : true,
    component: CollapsePage,
  },
  {
    path : '/page/devis-indiv',
    exact : true,
    component : DevisIndiv
  },
  {
    path : '/page/formules-ski',
    exact : true,
    component : FormuleSki
  },
  {
    path: '/page/devis-groupes',
    exact : true,
    component: DevisGroupes,
  },
  {
    path: '/station-ski',
    exact : true,
    component: StationsSki,
    loadData: () => loadSejourStation()
  },
  {
    path: '/affiliation',
    exact : true,
    component: Affiliation,
  },
  {
    path: '/promotions',
    exact : true,
    component: PagePromotion,
    loadData : () => loadPromotionPage()
  },
  // {
  //   path: '/promotions/ete',
  //   exact : true,
  //   component: PagePromotion,
  //   loadData : () => loadPromotionPage("sejours-france")
  // },
  // {
  //   path: '/promotions/ski',
  //   exact : true,
  //   component: PagePromotion,
  //   loadData : () => loadPromotionPage("sejours-ski")
  // },
  {
    path: '/post-reservation/:token',
    exact : true,
    component : PostReservation
  },
  {
    path: '/reservation/payement-status/:orderId',
    exact : true,
    component : ConfirmPayement
  },
  {
    path: '/reservation/:sejour?/:slug?/:value?/:etape?',
    exact : true,
    component: TunnelAchat,
  },
  {
    path: '/404',
    exact : true,
    component: PageNotFound,
  },
  {
    path: '/recherche',
    exact : true,
    component: Recherche,
  },
  {
    path: '/recherche?',
    exact : true,
    component: Recherche
  },
  {
    path : '/sejours-ski/location-vetement-ski',
    exact : true,
    component : LocationVetement
  },
  {
    path : '/vente-flash',
    exact : true,
    component : VenteFlash
  },
  {
    path : '/cookies',
    exact : true,
    component : CookiesPage
  },
  {
    path : '/sejours-ski/location-materiel-ski/:slug?',
    exact : true,
    component : templateLocationMateriel,
    loadData: (match) => loadPages(2, match.params.slug)
  },
  {
    path : '/page/:slug',
    exact : true,
    component : templateLocationMateriel,
    loadData: (match) => loadPages(1, match.params.slug)
  },
  {
    path: '/:sejour',
    exact : true,
    component: Sejour,
    loadData: (match) => loadSejour(match.params.sejour, undefined, undefined)
  },
  {
    path: '/:sejour/:slug',
    exact : true,
    component: Recherche,
    loadData: (match) => loadSejour(match.params.sejour, match.params.slug, undefined)
  },
  {
    path: '/:sejour/:slug/:value',
    exact : true,
    component: Offre,
    loadData: (match) => loadSejour(match.params.sejour, match.params.slug, match.params.value)
  }
]

function getRoutes (location, results, state) {
  return (
    <Switch>
      {typeof location === "undefined" ?
          renderRoutes(Routes, {results : results, state : state})
      :
        renderRoutes(Routes, {location : location, results : results, state : state})
      }
    </Switch>
  )
}

export default getRoutes
