import axios from 'axios'
import { getBoundingBox } from 'geolocation-utils'
import defaults from "lodash/defaults"
import menu from './menu.json'

export default class API {
   constructor() {
     let myHeaders = new Headers()
     myHeaders.append("Accept", "application/json")
     this.options = {
       method : 'GET',
       headers : myHeaders,
       cache : 'default',
       mode : "cors"
     }
     this.baseUrl = "https://api.vtr-voyages.fr/api"

     let credentials = {}
     const auth = {username : process.env.REACT_APP_ELK_USERNAME, password : process.env.REACT_APP_ELK_PASSWORD}
     credentials['auth'] = auth
     credentials['withCredentials'] = false
     const config = defaults(credentials, {
       baseUrl : process.env.REACT_APP_SEO_URL,
       timeout : process.env.REACT_APP_ELASTIC_TIMEOUT,
       headers:{
         'Content-Type': 'application/json',
         'Accept': 'application/json'
       }
     })
     this.axios = axios.create(config)
   }

   getAxiosConfig() {
      let credentials = {}
      const auth = {username : process.env.REACT_APP_ELK_USERNAME, password : process.env.REACT_APP_ELK_PASSWORD}
      credentials['auth'] = auth
      credentials['withCredentials'] = false
      const config = defaults(credentials, {
        baseUrl : process.env.REACT_APP_SEO_URL,
        timeout : 5000,
        headers : {}
      })
      return config
   }

   async handleResponse(response) {
     if (response.status !== 200) {
       throw new Error()
     }
     else {
       return await response.json()
     }
   }

   async sendNewsLetterEmail(email) {
      let response
      let body = {
        email : email
      }
      if (process.env.NODE_ENV === "development") {
        response = await axios.post("https://local-api.vtr-voyages.fr/sendEmailNewsLetter", body)
        return response.data
      }
      else {
        response = await axios.post("/sendEmailNewsLetter", body)
        return response.data
      }
   }

   async getMenu() {
     //let response = await axios.get(this.baseUrl + "/menus", {headers : {"Accept" : "application/json"}})
     //console.log(JSON.stringify(response.data))
     return (menu)
     //return response.data
   }

   async checkCodePromo(sejour_id, start_date, end_date, code_promo = "") {
      let body = {
        start_date : start_date,
        end_date : end_date,
        sejour_id : sejour_id,
        code_promo : code_promo
      }

      try {
        let response
        if (process.env.NODE_ENV === "development") {
          response = await axios.post("http://localhost:9090/sejours/checkCodePromo", body)
          console.log(response)
          return response.data
        }
        else {
          response = await axios.post("https://api2.vtr-voyages.fr/sejours/checkCodePromo", body)
          return response.data
        }
      }
      catch (e) {
        console.log(e.response)
        return {status : 401, data : e.response.data.data}
      }
   }

   async getCheckoutInfo(sejour_id, typo_id, particpants, start_date, end_date, code_promo = "") {
     let body = {
       start_date : start_date,
       end_date : end_date,
       sejour_id : sejour_id,
       typo : typo_id,
       particpants : particpants,
       code_promo : code_promo
     }

     try {
       let response
       if (process.env.NODE_ENV === "development") {
        let dispo = await axios.post('http://localhost:9090/booking/checkAvailability', body)
         response = await axios.post("http://localhost:8181/getOrderInfo", body)
         if (dispo.data === "arkiane") {
           response.data.arkiane = true
         }
         else if (dispo.data === "odalys") {
          response.data.odalys = true
         }
         return response.data
       }
       else {
        let dispo = await axios.post('https://api2.vtr-voyages.fr/booking/checkAvailability', body)
        response = await axios.post("/getOrderInfo", body)
        if (dispo.data === "arkiane") {
          response.data.arkiane = true
        }
        else if (dispo.data === "odalys") {
          response.data.odalys = true
        }
        return response.data
       }
     }
     catch (e) {
       console.log(e.response.data)
       return {status : 401, data : e.response.data}
     }
   }

   async saveInformationsGuest(dat) {
     let body = {
       body : dat
     }

     try {
       let response
       if (process.env.NODE_ENV === "development") {
         response = await axios.post("https://www.vtr-voyages.fr/saveInformationsGuest", body)
         return response.data
       }
       else {
         response = await axios.post("/saveInformationsGuest", body)
         return response.data
       }
     }
     catch (e) {
       return {status : 401, data : e.response.data}
     }
   }


   async getGuestInfo(token) {
     let body = {
       token : token
     }

     try {
       let response
       if (process.env.NODE_ENV === "development") {
        response = await axios.post("https://www.vtr-voyages.fr/getInformationsGuest", body)
        return response.data
       }
       else {
         response = await axios.post("/getInformationsGuest", body)
         return response.data
       }
     }
     catch (e) {
       return {status : 401, data : e.response.data}
     }
   }

   async getResaInfo(id) {
    let body = {
      id : id
    }

    try {
      let response
      response = await axios.post("https://api2.vtr-voyages.fr/booking/getInfoResaClient", body)
      return response.data
    }
    catch (e) {
      return {status : 401, data : e.response.data}
    }
  }

   async getOnVousEmmene(id) {
     let myRequest = new Request(this.baseUrl + "/good-deals/" + id, this.options)

     let response = await fetch(myRequest)
     return await this.handleResponse(response)
   }

   async sendFormGroupes(body) {
     if (process.env.NODE_ENV === "development") {
      await axios.post("https://local-api.vtr-voyages.fr/express/sendEmail", body)
     }
     else {
        await axios.post("/express/sendEmail", body)
     }
   }

   async saveFormGroupes(formData) {
       console.log('Sending data here ...')
       if (process.env.NODE_ENV === "development") {
           await axios.post('http://localhost:9090/groupe/createInquiry', formData);
       }
       else {
           await axios.post('https://api2.vtr-voyages.fr/groupe/createInquiry', formData);
       }
   }

   async sendFormIndiv(body) {
    if (process.env.NODE_ENV === "development") {
      await axios.post("https://local-api.vtr-voyages.fr/express/sendEmailFormIndiv", body)
    }
    else {
        await axios.post("/express/sendEmailFormIndiv", body)
    }
   }

   async sendFormPostResevation(dossier, age_changed = false) {
     let body = {
       numero_dossier : dossier,
       age_changed : age_changed
     }

     if (process.env.NODE_ENV === "development") {
      await axios.post("http://localhost:8181/sendEmail/confirmationPostReservation", body)
     }
     else {
        await axios.post("/sendEmail/confirmationPostReservation", body)
     }
   }

   async getSejoursStationDynamic(station) {
     let query = {
          "query":{
            "function_score":{
                "query":{
                  "match_all":{
      
                  }
                },
                "functions":[
                  {
                      "filter":{
                        "match":{
                            "nous_contacter":true
                        }
                      },
                      "weight":0
                  },
                  {
                      "filter":{
                        "match":{
                            "nous_contacter":false
                        }
                      },
                      "weight":70
                  },
                  /*{
                      "field_value_factor":{
                        "field":"weight"
                      }
                  },*/
                    {
                        "filter": {
                            "bool": {
                                "must_not": {
                                    "exists": {
                                        "field": "min_price"
                                    }
                                }
                            }
                        },
                        "weight": 0
                    }
                ],
                "max_boost":1000,
                "score_mode":"sum",
                "boost_mode":"multiply",
                "min_score":0
            }
          },
          "post_filter":{
            "bool":{
                "must":[
                  {
                      "term":{
                        "tags": station
                      }
                  }
                ]
            }
          },
          "size":20,
          "sort":[
            {
                "_score":"desc"
            }
          ],
          "_source":[
            "title",
                  "type_sejour",
                  "miniature",
                  "min_price",
                  "min_price_dates",
                  "localisation",
                  "promo_price",
                  "link"
          ]
      }

      let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
      return res.data.hits.hits
   }

  async getPages(prefix, slug) {
    let resTag = []
  
    if (typeof slug  === "undefined") {
      slug = "defaut"
    }
    let query = {
      "query":{
         "bool":{
            "must":[
               {
                  "term":{
                     "slug.raw":slug
                  }
               },
               {
                "term":{
                   "prefix": prefix
                }
              },
              {
                "term":{
                   "is_active": 1
                }
             }
            ]
         }
      },
      "size":"1"
    }

    let res = await this.axios.post(process.env.REACT_APP_PAGES_URL + "/_search", query)

    if (res.data.hits.hits.length > 0) {
      if (res.data.hits.hits[0]._source.show_sejour_carousel === 1) {
        query = {
          "query": {
            "bool" : {
              "filter":{
                "term":{
                  "tags.raw": res.data.hits.hits[0]._source.selectedTagLabel
                }
              }
            },
          },
          "size":200,
          "_source":[
            "title",
            "type_sejour",
            "miniature",
            "min_price",
            "min_price_dates",
            "localisation",
            "promo_price",
            "link"
          ]
        }

        resTag = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
        console.log(resTag.data.hits.hits)
        if (resTag.data.hits.hits.length > 0) {
          return {
            pages : res.data.hits.hits,
            promotion : resTag.data.hits.hits
          }  
        }
        return {
            pages : res.data.hits.hits,
            promotion : resTag.data.hits.hits
        }
      }
    } 
    return {
      pages : res.data.hits.hits,
      promotion : []
    }  
  }

  async getPromotion(filter) {
     let query
     if (!filter) {
       query = {
         "query": {
           "bool" : {
             "must" : {
               "term" : {
                 "nous_contacter" : false
               }
             }
           }
         },
         "post_filter" : {
           "range" : {
             "promo_price" : {
               "gte" : 100,
               "boost" : 2.0
             }
           },
         },
         "size":500,
         "_source":[
           "title",
           "type_sejour",
           "miniature",
           "min_price",
           "min_price_dates",
           "localisation",
           "promo_price",
           "link"
         ]
       }
     }
     else {
       query = {
         "query": {
           "bool" : {
             "must" : {"term" : { "nous_contacter" : false }},
             "filter":{
               "term":{
                 "tags.raw": filter
               }
             }
           },
         },
         "post_filter" : {
           "range" : {
             "promo_price" : {
               "gte" : 100,
               "boost" : 2.0
             }
           },
         },
         "size":200,
         "_source":[
           "title",
           "type_sejour",
           "miniature",
           "min_price",
           "min_price_dates",
           "localisation",
           "promo_price",
           "link"
         ]
       }
     }

     let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
     return res.data.hits.hits
   }

   async getCarousel(slug) {
     let query = {
      "query":{
        "bool":{
           "must":[
              {
                 "term":{
                    "sejour.raw": slug
                 }
              }
           ]
        }
      },
     "size":"5"
     }

     if (slug === "home") {
      query = {
        "query":{
          "bool":{
             "must":[
                {
                   "term":{
                      "homePage": 1
                   }
                }
             ]
          }
        },
       "size":"5"
       }
     }

     let res = await this.axios.post(process.env.REACT_APP_CAROUSEL_URL + "/_search", query)
     return res.data.hits.hits
   }

   async getSeo(slug, station_ski) {
    let query
    if (station_ski === false || station_ski !== "station-ski") {
      query = {
        "query" : {
            "bool" : {
              "must" : [
                {
                  "term" : {
                    "slug.raw" : slug
                  }
                },
                {
                  "term" : {
                    "station" : false
                  }
                }
              ]
            },
          },
          "_source": {"excludes" : ["footer"]},
          "size" : "1"
      }
    }
    else {
      query = {
        "query" : {
            "bool" : {
              "must" : [
                {
                  "term" : {
                    "slug.raw" : slug
                  }
                },
                {
                  "term" : {
                    "station" : true
                  }
                }
              ]
          }
        },
        "_source": {"excludes" : ["footer"]},
        "size" : "1"
      }
    }

     let res = await this.axios.post(process.env.REACT_APP_SEO_URL + "/_search", query)
     if (res.data.hits.hits.length > 0) {
      if (res.data.hits.hits[0]._source.station) {
        let info = await this.axios.post("https://api2.vtr-voyages.fr/elastic/search/searchStation", {name : slug})
        res.data.hits.hits[0]._source.info = info.data.data
      }
     }
     return res.data.hits.hits
   }

   async getSejourSimilaires(marker, type_sejour, link) {
     const margin = 20000
     const locations = [ marker ]
     let boundingBox = getBoundingBox(locations, margin)

     let query = {
       "query": {
         "bool": {
          "should" : {
            "term" : {
              "nous_contacter" : false
            },
          },
           "must": {
             "term": {
               "type_sejour.raw": type_sejour
             }
           },
           "must_not" : {
             "term" : {
               "link.raw" : link
             }
           },
           "filter" : {
              "geo_bounding_box" : {
               "localisation.location" : {
                 "top_left" : {
                   "lat": boundingBox.topLeft.lat,
                   "lon": boundingBox.topLeft.lon
                 },
                 "bottom_right" : {
                   "lat": boundingBox.bottomRight.lat,
                   "lon": boundingBox.bottomRight.lon
                 }
               }
             }
           }
         }
       },
       "_source" : [ "title", "type_sejour", "miniature", "localisation.ville", "localisation.station", "link", "teaser_text", "services"],
       "size" : 4
     }

     let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
     return res.data.hits.hits
   }

   async getPromotions(sejour = null) {
     let query
     if (sejour !== null) {
       query = {
         "query":{
           "bool":{
             "should" : {
               "range" : {
                 "promo_price" : {
                   "gte" : 100,
                   "boost" : 2.0
                 }
               }
             },
             "filter":{
               "term":{
                 "tags.raw": sejour
               }
             }
           }
         },
         "size":20,
         "_source":[
           "title",
           "miniature",
           "min_price",
           "localisation",
           "promo_price",
           "link"
         ]
       }
     }
     else {
       query = {
         "query": {
           "range" : {
             "promo_price" : {
               "gte" : 100,
               "boost" : 2.0
             }
           }
         },
         "size":20,
         "_source":[
           "title",
           "miniature",
           "min_price",
           "localisation",
           "promo_price",
           "link"
         ]
       }
     }

     let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
     return res.data.hits.hits
   }

   async getCoupCoeur(sejour) {
     let query = {
       "query":{
          "bool":{
            "must": [
              {
                "term" : {
                  "heart_stroke": true
                }
              },
              {
                "term" : {
                  "nous_contacter" : false
                }
              },
              {
                "term" : {
                  "type_sejour.raw" : sejour.indexOf("sejours") === -1 ? "sejours-" + sejour : sejour
                }
  
              }
            ]
          }
       },
       "size":3,
       "sort":[
          {
             "_score":"desc"
          }
       ],
       "_source":[
          "title",
          "miniature",
          "localisation",
          "link",
          "type_sejour"
       ]
     }

     
     let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
     return res.data.hits.hits
   }

   async getTripAdvisorReviews(slug) {
     let data = await axios.post("https://api2.vtr-voyages.fr/hebergement/getTripAdvisorReviews", {slug : slug})
     return data.data
   }

   async getStationInfo(slug) {
    let data = await axios.post("https://api2.vtr-voyages.fr/hebergement/getStationInfo", {slug : slug})
    return data.data
   }

   async getDestinationsPopulaires(sejour) {
     let query
    if (sejour === "colonies-de-vacances" || sejour === "sejours-sportifs") {
     query = {
         "query":{
           "bool":{
             "filter":{
               "term":{
                 "tags.raw": sejour
               }
             },
             "should" : {
               "term" : {
                 "nous_contacter" : false
               },
             },
           }
         },
         "post_filter":{
            "range":{
               "avis.nb_etoiles":{
                  "gte":2,
                  "lte":100
               }
            }
         },
         "size":4,
         "_source":[
           "type_sejour",
           "prestations.formule",
           "prestations.all_options",
           "hebergement",
           "nous_contacter",
           "title",
           "miniature",
           "min_price",
           "promo_price",
           "localisation",
           "avis",
           "link"
         ]
     }
    }
    else {
      query = {
        "query":{
          "bool":{
            "filter":{
              "term":{
                "tags.raw": sejour
              }
            },
            "should" : {
              "term" : {
                "nous_contacter" : false
              },
            },
          }
        },
        "post_filter":{
           "range":{
              "avis.nb_etoiles":{
                 "gte":2,
                 "lte":100
              }
           }
        },
        "size":4,
        "_source":[
          "type_sejour",
          "nous_contacter",
          "title",
          "miniature",
          "min_price",
          "promo_price",
          "localisation",
          "avis",
          "link"
        ]
    }
    }

     let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
     return res.data.hits.hits
   }

   async getMeilleurVentes(sejour) {
    let query
     if (sejour === "colonies-de-vacances" || sejour === "sejours-sportifs") {
      query = {
        "query" : {
          "function_score": {
            "query": { "term":{ "tags.raw": sejour } },
            "functions": [
              {
                "filter": { "match": { "nous_contacter": true } },
                "weight": 0
              },
              {
                "filter": { "match": { "nous_contacter": false} },
                "weight": 70
              },
              {
                "field_value_factor": { "field": "weight" }
              },
                {
                    "filter": {
                        "bool": {
                            "must_not": {
                                "exists": {
                                    "field": "min_price"
                                }
                            }
                        }
                    },
                    "weight": 0
                }
            ],
            "max_boost": 1000,
            "score_mode": "sum",
            "boost_mode": "multiply",
            "min_score" : 70
          },
        },
 
          "size":3,
          "_source":[
            "type_sejour",
            "title",
            "miniature",
            "prestations.formule",
            "prestations.all_options",
            "hebergement",
            "nous_contacter",
            "min_price",
            "promo_price",
            "localisation",
            "avis",
            "link"
          ]
      }
     }
     else {
      query = {
        "query" : {
          "function_score": {
            "query": { "term":{ "tags.raw": sejour } },
            "functions": [
              {
                "filter": { "match": { "nous_contacter": true } },
                "weight": 0
              },
              {
                "filter": { "match": { "nous_contacter": false} },
                "weight": 70
              },
              {
                "field_value_factor": { "field": "weight" }
              },
                {
                    "filter": {
                        "bool": {
                            "must_not": {
                                "exists": {
                                    "field": "min_price"
                                }
                            }
                        }
                    },
                    "weight": 0
                }
            ],
            "max_boost": 1000,
            "score_mode": "sum",
            "boost_mode": "multiply",
            "min_score" : 70
          },
        },
 
          "size":3,
          "_source":[
            "type_sejour",
            "title",
            "miniature",
            "nous_contacter",
            "min_price",
            "promo_price",
            "localisation",
            "avis",
            "link"
          ]
      }
     }
  

     let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
     return res.data.hits.hits
   }

   async getCoupsCoeurs() {
    let query = {
      "query":{
          "function_score": {
            "query" : {
              "bool" : {
                "must": [
                  {
                    "term" : {
                      "heart_stroke": true
                    }
                  },
                  {
                    "term" : {
                      "nous_contacter" : false
                    }
                  }
                ]
              }
            },
            "functions": [
                {
                    "filter": { "match": { "type_sejour": "colonies-de-vacances" } },
                    "weight": 0
                },
                {
                    "filter": { "match": { "type_sejour": "sejours-ski"} },
                    "weight": 70
                },
                {
                  "field_value_factor": { "field": "weight" }
                }
            ],
            "max_boost": 1000,
            "score_mode": "sum",
            "boost_mode": "multiply",
            "min_score" : 0
        },
      },
      /* "post_filter":{
        "term":{
           "type_sejour_filter.raw":"Ski"
        }
     }, */
      "size":4,
      "_source":[
        "heart_stroke", 
        "title",
        "type_sejour",
        "miniature",
        "min_price",
        "localisation",
        "promo_price",
        "min_price_dates",
        "hebergement",
        "link"
      ]
  }

    let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
    return res.data.hits.hits
   }
  
   async getPlusPopulaires(id) {
     let query = {
       "query":{
         "bool" : {
           "must": [
             {
               "range" : {
                 "weight" : {
                   "gte" : 75,
                   "boost" : 3.0
                 }
               }
             },
             {
               "term" : {
                 "nous_contacter" : false
               }

             }
           ]
         }
       },
       "post_filter":{
        "term":{
           "type_sejour_filter.raw":"Ski"
        }
     },
       "size":4,
       "_source":[
         "title",
         "type_sejour",
         "miniature",
         "min_price",
         "localisation",
         "promo_price",
         "min_price_dates",
         "link"
       ]
     }

     let res = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", query)
     return res.data.hits.hits
   }

   generateDerniereMinutesQuery(lt ,gte) {
     let query = {
       "query":{
         "bool" : {
           "must": [
             {
               "range":{
                 "hebergement.grille.from":{
                   "lt": lt,
                   "gte": gte
                 }
               }
             },
             {
               "range" : {
                 "min_price" : {
                   "gte" : 200,
                   "boost" : 2.0
                 }
               }
             },
             {
               "range" : {
                 "promo_price" : {
                   "gte" : 250,
                   "boost" : 2.0
                 }
               }
             },
             {
               "term" : {
                 "nous_contacter" : false
               }

             }
           ]
         }
       },
       "size":6,
       "sort":[ { "_score":"desc" } ],
       "_source":[
         "title",
         "localisation",
         "link",
         "min_price",
         "promo_price",
         "from",
         "to"
       ]
     }

     return query
   }

   async getDerniereMinutes(id) {
     let today = new Date()
     let nextWeek = new Date()

     let res_this_week = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", this.generateDerniereMinutesQuery(nextWeek.setDate(nextWeek.getDate() + 7), today.getTime()))
     let res_next_week = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", this.generateDerniereMinutesQuery(nextWeek.setDate(nextWeek.getDate() + 14), today.setDate(today.getDate() + 7)))
     let res_far_week = await this.axios.post(process.env.REACT_APP_ELASTIC_URL + "/_search", this.generateDerniereMinutesQuery(nextWeek.setDate(nextWeek.getDate() + 21), today.setDate(today.getDate() + 14)))
     return ({this_week : res_this_week.data.hits.hits, next_week : res_next_week.data.hits.hits, far_week : res_far_week.data.hits.hits})
   }

   async getBonPlans(id) {
     let myRequest = new Request(this.baseUrl + "/good-deals/" + id, this.options)

     let response = await fetch(myRequest)
     return await this.handleResponse(response)
   }

   async sendAffiliationEmail(info) {
       try {
           const body = {...info}

           if (process.env.NODE_ENV === "development") {
               const response = await axios.post("http://localhost:8181/sendAffiliationEmail", body)
               // const response = await axios.post("https://local-api.vtr-voyages.fr/sendAffiliationEmail", body)
               return response.data.data
           }
           else {
               const response = await axios.post("/sendAffiliationEmail", body)
               return response.data.data
           }
       } catch (err) {
           console.log(err)
       }
   }

  async sendContactEmail(body) {
    try {
      let response = null

      if (process.env.NODE_ENV === "development") {
          response = await axios.post('http://localhost:8181/sendContactMessage', body)
      }
      else {
          response = await axios.post('/sendContactMessage', body)
      }

      return response
    } catch (err) {
        console.log(err)
        return null
    }
  }
 }
