const initialState = {
    favorites : typeof localStorage !== "undefined" ? JSON.parse(localStorage.getItem('vtr-favorites')) : [],
}

export function loadFavorites() {
    return dispatch => {
        if (!localStorage) {
            return;
        }
        let favs = localStorage.getItem('vtr-favorites');
        dispatch({ type : "SET_FAVORITES", payload: favs? JSON.parse(favs) : []});
    }
}

export function addToFavorite(favorite) {
    return dispatch => dispatch({ type : "ADD_FAVORITE", payload: favorite});
}

export function deleteFromFavorite(refLink) {
    return dispatch => dispatch({ type : "DELETE_FAVORITE", payload: refLink});
}

const favorisReducer = ( state = initialState,  action) => {
    switch (action.type) {
        case "SET_FAVORITES" :
            state = {...state, favorites : action.payload};
            localStorage.setItem('vtr-favorites', JSON.stringify(action.payload));
            break;
        case "ADD_FAVORITE":
            state = {...state, favorites : [...state.favorites, action.payload]};
            localStorage.setItem('vtr-favorites', JSON.stringify(state.favorites));
            break;
        case "DELETE_FAVORITE":
            const index = state.favorites.findIndex(fav => fav.link === action.payload);
            if (index > -1) {
                let favs = [...state.favorites];
                favs.splice(index, 1);
                state = {...state, favorites : favs};
                localStorage.setItem('vtr-favorites', JSON.stringify(state.favorites));
            }
            break;
        default :
            break;
    }
    return state
}

export default favorisReducer;