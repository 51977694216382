import React from 'react';
import SideNavPage from '../navbar/mobile_version'
import Footer from '../footer/footer'
import MyCookies from '../cookies/cookies';
import getRoutes from './routes/routes'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies, CookiesProvider } from 'react-cookie';
import 'bootstrap-css-only/css/bootstrap.min.css';
import './mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css'
 
class App extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  render() {
    return (
      <CookiesProvider>
          <React.Fragment>
            <SideNavPage/>
            { getRoutes(this.props.location, this.props.results, this.props.state) }
             <MyCookies />
            <Footer/>
          </React.Fragment>
      </CookiesProvider>
    )
  }
}

export default withCookies(App);
